/* Join.css */

.join-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 100%; /* Ensure it takes at least the full height of the viewport */
    background-color: black;
    color: white;
  }
  
  .logo {
    position: fixed; /* The logo will stay at the top left, no matter how the user scrolls */
    top: 20px;
    left: 20px;
    z-index: 10; /* Ensure the logo is on top of other components */
    cursor: pointer; /* Change cursor to pointer when hovering over the logo */
  }
  
  .playlist-users-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .playlist-container {
    margin-right: 20px;
  }
  
  .users-container {
    max-height: 400px;
    overflow-y: auto;
    padding-top: 2rem;
  }
  
  .user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  
  /* Use media query to change flex-direction to column on small screens */
  @media (max-width: 600px) {
    .playlist-users-container {
      flex-direction: column;
      align-items: center; /* Center the components when stacked vertically */
      justify-content: center;
    }
  }
  
  